<template>
    <section class="bg">
        <div class="modal-wrap">
        <modal container-class="step-1">
            <template v-slot:header>Konfiguracja</template>
            <template v-slot:body>
                <p>Wybierz model ogrodzenia 
                do wykonania kalkulacji</p>
                <ul class="selector">
                    <li v-for="(product, key) in $db" :key="key">
                        <a href="#" @click.stop.prevent="$emit('product', key)"  @mousemove="move(key,$event)" @mouseenter="mouseStart(key, $event)" @mouseleave="mouseLeave(key)">
                            <span class="ph">
                                <img :src="'products/cover/'+key + '.jpg'" />
                                <span class="over" :style="'background-image: url(products/cover/' + key + '_hover.jpg)'"></span>
                            </span>
                            <span class="tit">
                                {{ product.name }}
                            </span>
                        </a>
                    </li>
                </ul>
            </template>
        </modal>
        </div>
        <div class="product–colors" ref="over" v-show="show">
            <template v-if="show">
                <strong>Dostępne kolory</strong>
                <ul>
                    <li v-for="c in $db[show].colors" :key="c" >
                        <span class="ph" :style="'background-image: url(' + 'products/color/' + show + '/' + c + '.jpg' + ')'"></span>
                        <span>
                            {{colorLabel(c)}}
                        </span>
                    </li>
                </ul>
            </template>
        </div>
    </section>
</template>
<script>
import Modal from '@/widgets/Modal'
export default {
    components: {Modal},
    data() {
        return {
            show: null
        }
    },
    methods: {

        move(product, event) {
            this.$refs.over.style.left = event.clientX + window.scrollX + 10 + 'px'
            this.$refs.over.style.top = event.clientY + window.scrollY + 10 + 'px'
        },
        mouseStart(product, event) {
            this.show = product
            this.$refs.over.style.left = event.clientX + 'px'
            this.$refs.over.style.top = event.clientY + 'px'
        },
        mouseLeave(product) {
            this.show = null
        },
        colorLabel(color) {
            return this.$parent.colorLabel(color)
        }
    }
}
</script>
<style lang="scss">
.bg {
    background: url('../assets/images/bg.jpg') no-repeat center center;
    background-size: cover;
    
    .modal-wrap {
        position: relative;
        height: 100%;
    }
}
.product–colors {
    position: absolute;
    top: 0px;
    left: 0px;
    background: white;
    z-index:9999;
    border: 2px solid #abb5c1;
    padding: 10px 20px;
    strong {
        font-size: 14px;
        display: block;
        padding-bottom: 8px;
    }
    ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        li {
            display: inline-block;
            min-width: 73px;
            padding-right: 5px;
            font-size: 12px;
            .ph {
                display: block;
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
            }
        }
    }
}
.step-1 {
    position: absolute !important;
    height: 100%;
    .modal-header {
        font-size: 30px;
        color: #878e00;
        padding: 10px 0px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 10px;
    }
    .modal-body {
        margin-top: 0px;
        p {
            color: #535353;
            font-size: 24px;
            text-align :center;
            letter-spacing: 1px;
            padding-bottom: 15px;
            border-bottom: 1px solid #e5e5e5;
        } 
    }
    .selector {
        
        padding: 0px;
        margin-top: 30px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: auto;
        max-height:400px;
        li {
            position: relative;
            width: 300px;
        }
    }

    .selector a {
        display: block;
        text-decoration: none;
        color: black;
        margin: 0px 10px;
        margin-bottom: 25px;
        transition: all .4s;
        overflow: auto;
   
        
        .ph {
            
            height: 140px;
            background: silver;
            display: block;
            overflow: hidden;
            position: relative;
            width: 100%;

            img {
                width: 100%;
            }
            
            .over {
                border: 7px solid #e5e9ee;
                width: 100%;
                transition: all .4s;
                background-color: white;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                display: block;
                content: '';
                opacity: 0;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
            }
        }
        &:hover .ph .over {
            opacity: 1;
        }
        .tit {
            display: block;
            font-size: 16px;
            font-family: roboto;
            padding-top: 5px;
            font-weight: bold;

        }
    }
}
.step-1 .modal-container {
    width: 1300px;
}
</style>
