import Vue from 'vue'
import App from './App.vue'

import '@fontsource/ubuntu/latin-400.css'
import '@fontsource/ubuntu/latin-500.css'
import '@fontsource/ubuntu/latin-300.css'
import '@fontsource/ubuntu/latin-700.css'
import '@fontsource/ubuntu/latin-ext-400.css'
import '@fontsource/ubuntu/latin-ext-500.css'
import '@fontsource/ubuntu/latin-ext-300.css'
import '@fontsource/ubuntu/latin-ext-700.css'

import '@fontsource/roboto/latin-700.css'
import '@fontsource/roboto/latin-ext-700.css'

import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-ext-400.css'

import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-ext-300.css'

import 'modern-css-reset'

import './assets/style/app.scss'

import VueSilentbox from 'vue-silentbox'

import * as gl from 'vue-gl'

Object.entries(gl).forEach(([name, component]) => Vue.component(name, component))

Vue.use(VueSilentbox)

Vue.config.productionTip = false
Vue.prototype.$db = {
  /*belluno: {
    name: 'Ogrodzenie Belluno',
    colors: ['kwarcytowy', 'pergaminowa-biel', 'antracytowy', 'pastello'],
    photos: 2,
    products: {
      1 : {
        id : 1,
        name: 'pustak',
        width : 50,
        height:20,
        length: 20,
        begin: false,
        internal: true
      },
      2 : {
        id : 2,
        name: 'daszek',
        width : 50,
        height:4,
        length: 20,
        begin: false,
        internal: true
      }
     
    },
    foundation: {
      heightScale : 20,
      multipleVariants: false,
      settings: false,
      variants: {
        1 : {
          products: [1],
          peak: [2]
        }
      }
    },
    column: {
      heightScale: 14,
      multipleVariants: true,
      variants: {
        1 : {
          width: 50,
          peak: [2],
          multiply: 1,
          products: [1]
        },
      }
    },
    wall: {
      minHeight: 20,
      heightScale: 20,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [1],
          peak: [2]
        }
      }
    }
  },*/
  fence_split: {
    name: 'Ogrodzenie Split',
    colors: ['popielaty', 'pastello', 'kasztanowy', 'bianco', 'nero'],
    photos: 9,
    products: {
      1 : {
        id : 1,
        name: 'daszek splitowany 2-stronnie',
        width : 22,
        height: 6,
        length: 27.6
      },
      2 : {
        id : 2,
        name: 'daszek splitowany 4-stronnie',
        width : 28,
        height: 6.5,
        length: 47
      },
      3 : {
        id: 3,
        name: 'daszek splitowany 4-stronnie',
        width: 42,
        height: 6.5,
        lengt: 42
      },
      4 : {
        id: 4,
        name: 'element splitowany 3-stronnie',
        width: 19,
        height: 14,
        length: 19,
        begin: true
      },
      5 : {
        id : 5,
        name: 'element splitowany 2-stronnie',
        width: 38,
        height: 14,
        length: 19,
        begin: false,
        internal: true,
        main: true
     
      },
      6 : {
        id : 6,
        name: 'splitowany 3-stronnie',
        width: 38,
        height: 14,
        length: 19,
        begin: true,
        internal: false
      },
      7 : {
        id : 7,
        name: 'element splitowany 4-stronnie',
        width: 38,
        height: 14,
        length: 19,
        begin: false,
        internal: false
      },
      8: {
        id : 8,
        name: 'element splitowany 4-stronnie',
        width: 38,
        height: 14,
        length: 38,
        begin: false,
        internal: false
      }
    },
    foundation: {
      heightScale : 14,
      multipleVariants: false,
      settings: true,
      variants: {
        1 : {
          products: [5],
          peak: [1,2]
        }
      }
    },
    column: {
      heightScale: 14,
      multipleVariants: true,
      variants: {
        8 : {
          width: 38,
          peak: [3],
          multiply: 1,
          products: [8]
        },
        7 : {
          width: 38,
          products: [7],
          multiply: 1,
          peak: [1, 2]
        },
        4 : {
          width: 57,
          products: [4,6],
          multiply: 1,
          peak: [1, 2]
        }
      }
    },
    wall: {
      minHeight: 14,
      heightScale: 14,
      multipleVariants: false,
      settings: true,
      type: true,
      variants: {
        1: {
          products: [4, 5, 6],
          peak: [1, 2],
          corner: 6
        }
      }
    }
    
  },
  fence_structural: {
    name: 'Ogrodzenie Strukturalne',
    colors: ['antracytowy'],
    photos: 22,
    products: {
      1 : {
        id : 1,
        name: 'element',
        width : 56.9,
        height: 7,
        length: 28.4,
        begin: false,
        internal: true,
        main: true
      },
      10: {
        id : 10,
        name: 'daszek',
        width: 56.9,
        height: 6,
        length: 32.4
      }
     
    },
    foundation: {
      heightScale : 7,
      multipleVariants: false,
      settings: false,
      variants: {
        1 : {
          products: [1],
          peak: [10]
        }
      }
    },
    column: false,
    wall: {
      minHeight: 7,
      heightScale: 7,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [1],
          peak: [10]
        }
      }
    }
    
  },
  fence_maxima: {
    name: 'Ogrodzenie Maxima',
    colors: ['pastello', 'popielaty', 'antracytowy', 'karbonowy'],
    photos: 7,
    products: {
      1 : {
        id : 1,
        name: 'daszek',
        width : 22,
        height: 4.5,
        length: 27
      },
      2 : {
        id : 2,
        name: 'element',
        width : 19,
        height: 14,
        internal: true,
        length: 19
      },
      3 : {
        id : 3,
        name: 'element',
        width : 38,
        height: 14,
        internal: true,
        main: true,
        length: 22
      },
      4 : {
        id : 4,
        name: 'element',
        width : 38,
        height: 14,
        length: 38
      },
      5 : {
        id : 5,
        name: 'daszek',
        width : 38,
        height: 4.5,
        length: 38
      }
     
    },
    foundation: {
      heightScale : 14,
      multipleVariants: false,
      settings: false,
      variants: {
        1 : {
          products: [3, 2],
          peak: [1]
        }
      }
    },
    column: {
      heightScale: 14,
      multipleVariants: true,
      variants: {
        3 : {
          width: 38,
          peak: [1],
          multiply: 1,
          products: [3]
        },
        4 : {
          width: 38,
          peak: [5],
          multiply: 1,
          products: [4]
        },
        2 : {
          width: 57,
          peak: [1],
          multiply: 1,
          products: [2, 3]
        }
      }
    },
    wall: {
      minHeight: 14,
      heightScale: 14,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [3,2],
          peak: [1]
        }
      }
    }
  },
  brick_split: {
    name: 'Cegła Split',
    colors: ['pastello', 'kasztanowy', /*'bianco', */ 'nero'],
    photos: 5,
    products: {
      1 : {
        id : 1,
        name: 'cegła',
        width : 25,
        height: 6.5,
        length: 12,
        begin: false,
        internal: true
      }
     
    },
    foundation: {
      heightScale : 6.5,
      multipleVariants: false,
      settings: false,
      variants: {
        1 : {
          products: [1],
          peak: []
        }
      }
    },
    column: {
      heightScale: 6.5,
      multipleVariants: false,
      variants: {
        1 : {
          width: 25,
          multiply: 4,
          peak: [],
          products: [1]
        }
      }
    },
    wall: {
      minHeight: 0,
      heightScale: 6.5,
      multipleVariants: false,
      settings: false,
      multiply: true,
      type: false,
      variants: {
        1: {
          products: [1],
          peak: []
        }
      }
    }
  },
  murja: {
    name: 'Murja',
    colors: ['antracytowy', /*'bialy',*/ 'ceglasty', 'srebrno-szary'],
    photos: 6,
    products: {
      1 : {
        id : 1,
        name: 'bloczek',
        width : 11.8,
        height: 4,
        length: 11.8,
        begin: false,
        internal: true
      },
      2 : {
        id : 2,
        name: 'bloczek',
        width : 11.8,
        height: 4,
        length: 15.9,
        begin: false,
        internal: true
      }
     
    },
    foundation: false,
    column: false,
    wall: {
      minHeight: 4,
      heightScale: 4,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [1, 2],
          peak: []
        }
      }
    }
  },
  massimo: {
    name: 'Natulit Massimo',
    colors: ['piaskowo-bezowy', 'bazaltowy-grafit', 'torfowy-braz', 'srebrno-szary'],
    photos: 6,
    products: {
      1 : {
        id : 1,
        name: 'bloczek',
        width : 20.8,
        height: 8,
        length: 17.3,
        begin: false,
        internal: true,
        main: true
      }
     
    },
    foundation: {
      heightScale : 8,
      multipleVariants: false,
      settings: false,
      variants: {
        1 : {
          products: [1],
          peak: []
        }
      }
    },
    column: false,
    wall: {
      minHeight: 8,
      heightScale: 8,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [1],
          peak: []
        }
      }
    }
  },
  garden_stone: {
    name: 'Kamień ogrodowy Split',
    colors: ['nero', 'pastello', 'kasztanowy'],
    photos: 3,
    products: {
      1 : {
        id : 1,
        name: 'kamień',
        width : 20,
        height: 10,
        length: 20,
        begin: false,
        internal: true
      },
      2 : {
        id : 2,
        name: 'kamień',
        width : 16,
        height: 10,
        length: 20,
        begin: false,
        internal: true
      }
     
    },
    foundation: false,
    column: false,
    wall: {
      minHeight: 0,
      heightScale: 10,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [1, 2],
          peak: []
        }
      }
    }
  },
  masto: {
    name: 'Murek Masto',
    colors: ['nero'],
    photos: 4,
    
    products: {
      1 : {
        id : 1,
        name: 'cegła',
        width : 25,
        height:15,
        length: 17.5,
        begin: false,
        internal: true
      },
      2 : {
        id : 2,
        name: 'cegła',
        width : 35,
        height:15,
        length: 17.5,
        begin: false,
        internal: true
      },
      3 : {
        id : 3,
        name: 'cegła',
        width : 40,
        height:15,
        length: 17.5,
        begin: false,
        internal: true
      }
     
    },
    foundation: false,
    column: false,
    wall: {
      minHeight: 0,
      heightScale: 15,
      multipleVariants: false,
      settings: false,
      type: true,
      variants: {
        1: {
          products: [1, 2, 3],
          peak: []
        }
      }
    }
  }
  
}
new Vue({
  render: h => h(App),
}).$mount('#app')
