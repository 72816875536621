<template>
    <section class="bg">
        <modal container-class="step-1 step-color" v-if="!start">
            <template v-slot:header>Konfiguracja</template>
            <template v-slot:body>
                <div class="product-info">
                    <div class="gallery">
                        <div class="main">
                            <div class="ph" :style="'background-image: url(products/photo/' + value.product + '/' + photo + '.jpg)'" @click="zoom(photo -1)"></div>
                        </div>
                        <div class="bar">
                            <img :src="'products/photo/' + value.product + '/' + i + '.jpg'" @click.stop="zoom(i - 1)" v-for="i in photos" :key="i" />
                        </div>
                    </div>
                    <div class="right">
                        <h2>{{ product.name }}</h2>
                        <p>Aby rozpocząć konfigurację wybierz kolor:</p>
                        <ul>
                            <li v-for="c in product.colors" :key="c" :class="{active: c == color }"   @click="color=c" >
                                <span class="ph" :style="'background-image: url(' + 'products/color/' + value.product + '/' + c + '.jpg' + ')'"></span>
                                <span>
                                    {{colorLabel(c)}}
                                </span>
                            </li>
                        </ul>
                        <div :class="{btns: true, disabled: color == null}">
                            <button class="prev" @click="$emit('prev')">cofnij</button>
                            <button class="next" @click="confirm">wybierz ogrodzenie</button>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <silent-box :gallery="gallery" ref="gallery" v-if="start" @silentbox-overlay-hidden="start = false"></silent-box>
    </section>
</template>
<script>
import Modal from '@/widgets/Modal'
export default {
    components: {Modal},
    props: ['value'],
    data() {
        return {
            photo: 1,
            color: null,
            start: false
        }
    },
    computed: {
        product() {
            return this.$db[this.value.product];
        },
        photos() {
            let nums = []
            for (var i = 1; i <= this.$db[this.value.product].photos; i++) {
                nums.push(i)
            }
            return nums
        },
        gallery() {
            let list =[]
            for (var i = 1; i <= this.$db[this.value.product].photos; i++) {
                list.push({
                    src : 'products/photo/' + this.value.product + '/' + i + '.jpg'
                })
            }

            return list
        }
    },
    methods: {
        colorLabel(color) {
            return this.$parent.colorLabel(color)
        },
        confirm() {
            if (this.color) {
                this.$emit('input', {...this.value, color: this.color}); 
                this.$emit('next')
            }
        },
        zoom(index) {
            this.start = false
            this.$nextTick(() => {
                this.start = true
                this.$nextTick(() => {
                    let c = this.$refs.gallery.openOverlay(this.$refs.gallery.gallery[index], index)
                })
            })
        }
    }
}
</script>
<style lang="scss">
#silentbox-gallery > a {
    display: none;
}
.product-info {
    display: flex;
    border-top: 1px solid #e5e5e5;
    padding-top: 25px;
    .gallery {
        width: 55%;
        display: flex;
        
        border-right: 1px solid #e5e5e5;
        .main {
            flex: 1;
            .ph {
                display: block;
                height: 370px;
                background: no-repeat;
                background-size: contain;
                background-position: top center;
            }
        }
        .bar {
            margin-left: 16px;
            width:115px;
            height: 370px;
            overflow: auto;
            padding-right: 25px;
            img {
                margin-bottom: 16px;
                cursor: pointer;
                display: block;
            }
        }
    }
    .right {
        width: 45%;
        padding-left: 25px;
        h2 {
            font-size: 30px;
        }
        p {
            text-align: left;
            border: 0px;
            padding: 0px;
            padding-top: 40px;
            font-size: 24px;
            font-weight: 300;
        }
        ul {
            padding: 0px;
            list-style: none;
            margin-right: -30px;;
            li {
                display: inline-block;
                cursor: pointer;
                width:135px;
                text-align: center;
            }
            .ph {
                display: block;
                width: 105px;
                height: 105px;
                margin: 0 auto;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
            }
            img {
                width: 105px;
                display: block;
            }
            .active .ph::after {
                content: '';
                display: block;
                border: 4px solid #878e00;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
        .btns {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            button {
                cursor: pointer;
                height: 60px;
                line-height: 60px;
                color: white;
                border-radius: 5px;
                border: 0px solid;
                background: #3e3e3e;
                font-size: 24px;
                letter-spacing: 1px;
                padding: 0px 20px;
                font-family: Ubuntu;
            }
            .prev::before {
                content: '';
                display: inline-block;
                width: 40px;
                vertical-align: middle;
                background: url(../assets/images/prev.png) no-repeat center left;
                height: 25px;
            }
            .next {
                background: #878e00;
            }
            .next::after {
                content: '';
                display: inline-block;
                width: 40px;
                background: url(../assets/images/next.png) no-repeat center right;
                vertical-align: middle;
                height: 25px;
            }
            &.disabled .next {
                background-color: #c8ccd0;
            }
        }
    }
}

</style>
