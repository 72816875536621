<template>
    <section class="configure" @click.stop.prevent="unselect()">
        <div :class="{'canvas-wrap' : true, 'has-gl' : gl}"  @wheel="onWheel" :style="'background-size: ' + (scale * 68) + 'px'">
            <div class="gl-canvas" v-if="gl">

                <vgl-renderer id="canvas"
                    :alpha="true"
                    :antialias="true">
                    <template #scene>

                        <vgl-scene>
                            <template v-for="(obj, index) in calculated.list">
                              
                            <vgl-mesh 
                                :positionX="obj.item.pos.x / meter *  scale"
                                :positionY="obj.item.data.height / 100 / 2 * scale"
                                :positionZ="obj.item.pos.y / meter * scale"
                                :rotationY="obj.deg ? obj.deg * pi / 180  * -1 : 0"
                                receive-shadow
                                 :key="'m' + index"
                                v-if="obj.type == 'column'"
                                >
                                <template #geometry>
                                    <vgl-box-geometry 
                                        :name="'m' + index"
                                        :width="0.2 * scale"
                                        :height="obj.item.data.height / 100 * scale"
                                        :depth="0.2"
                                    />
                                </template>
                                <template #material>
                                    <vgl-mesh-standard-material v-if="obj.type == 'column'">
                                        <template #map>
                                            <vgl-texture :wrapS="'repeat'" :wrapT="'repeat'" :repeatU="obj.lines" :repeatV="obj.rows">
                                                <template #image>
                                                    <vgl-image :src="'products/color/' + value.product + '/' + value.color + '.jpg'" />
                                                </template>

                                            </vgl-texture>
                                        </template>
                                    </vgl-mesh-standard-material>
                                </template>
                            </vgl-mesh>
                            <vgl-mesh 
                                :positionX="obj.point.x / meter * scale"
                                :positionY="obj.item.data.height / 100 / 2 * scale"
                                :positionZ="obj.point.y / meter * scale"
                                :rotationY="obj.deg ? obj.deg * pi / 180  * -1 : 0"
                                receive-shadow
                                 :key="'m' + index"
                                v-else-if="obj.type == 'wall'"
                                >
                                <template #geometry>
                                    <vgl-box-geometry 
                                        :name="'m' + index"
                                        :width="obj.meters * scale"
                                        :height="obj.item.data.height / 100 * scale"
                                        :depth="0.15"
                                    />
                                </template>
                                <template #material>
                                    <vgl-mesh-standard-material v-if="obj.type == 'wall'">
                                        <template #map>
                                            <vgl-texture :wrapS="'repeat'" :wrapT="'repeat'" :repeatU="obj.lines" :repeatV="obj.rows">
                                                <template #image>
                                                    <vgl-image :src="'products/color/' + value.product + '/' + value.color + '.jpg'" />
                                                </template>

                                            </vgl-texture>
                                        </template>
                                    </vgl-mesh-standard-material>
                                </template>
                            </vgl-mesh>
                            <vgl-mesh 
                                :positionX="obj.point.x / meter * scale"
                                :positionY="1.5 / 2 * scale"
                                :positionZ="obj.point.y / meter * scale"
                                :rotationY="obj.deg ? obj.deg * pi / 180  * -1 : 0"
                                receive-shadow
                                 :key="'m' + index"
                                v-else-if="obj.type == 'gate' || obj.type == 'wicket'"
                                >
                                <template #geometry>
                                    <vgl-box-geometry 
                                        :name="'m' + index"
                                        :width="obj.meters * scale"
                                        :height="1.5 * scale"
                                        :depth="0.01"
                                    />
                                </template>
                                <template #material>
                                    
                                    <vgl-mesh-standard-material>
                                        <template #map>
                                            <vgl-texture :wrapS="'repeat'" :wrapT="'repeat'" :repeatU="1" :repeatV="1">
                                                <template #image>
                                                    <vgl-image :src="'products/door2.png'" />
                                                </template>

                                            </vgl-texture>
                                        </template>
                                    </vgl-mesh-standard-material>
                                </template>
                            </vgl-mesh>
                            <vgl-mesh 
                                :positionX="obj.point.x / meter * scale"
                                :positionY="foundation.height / 100 / 2 * scale"
                                :positionZ="obj.point.y / meter * scale"
                                :rotationY="obj.deg ? obj.deg * pi / 180  * -1 : 0"
                                receive-shadow
                                 :key="'m' + index"
                                v-else-if="obj.type == 'line' && foundation.enabled"
                                >
                                <template #geometry>
                                    <vgl-box-geometry 
                                        :name="'m' + index"
                                        :width="obj.meters * scale"
                                        :height="foundation.height / 100 * scale"
                                        :depth="0.15"
                                    />
                                </template>
                                <template #material>

                                    <vgl-mesh-standard-material>
                                        <template #map>
                                            <vgl-texture :wrapS="'repeat'" :wrapT="'repeat'" :repeatU="obj.lines" :repeatV="obj.rows">
                                                <template #image>
                                                    <vgl-image :src="'products/color/' + value.product + '/' + value.color + '.jpg'" />
                                                </template>

                                            </vgl-texture>
                                        </template>
                                    </vgl-mesh-standard-material>
                                    
 
                                </template>
                            </vgl-mesh>
                        </template>
                            
                        <vgl-ambient-light  :positionX="$el.offsetWidth / meter" :positionZ="$el.offsetHeight / meter" :positionY="8" rotation="lookAt"
                        :lookAtX="$el.offsetWidth / meter / 2 * scale"
                        :lookAtZ="$el.offsetHeight / meter / 2 * scale"
                        :lookAtY="0"
                        :intensity="0.9"
                            />

                        <vgl-directional-light  :positionX="$el.offsetWidth / meter / 2" :positionZ="$el.offsetHeight / meter / 2 " :positionY="20" rotation="lookAt"
                        :lookAtX="$el.offsetWidth / meter / 2 * scale"
                        :lookAtZ="$el.offsetHeight / meter / 2 * scale"
                        :lookAtY="0"
                        :intensity="0.3"
                            />
                            
                        </vgl-scene>
                    </template>
                    <template #camera>
                        <vgl-perspective-camera :positionX="$el.offsetWidth / meter / 2  * scale + 10" :positionZ="$el.offsetHeight / meter / 2  * scale + 10" :positionY="5" rotation="lookAt"
                        :lookAtX="calculated.lookAt.x"
                        :lookAtZ="calculated.lookAt.z"
                        :lookAtY="calculated.lookAt.y"
                        :zoom="2"
                        :aspect="undefined"
                         />
                    </template>
                </vgl-renderer>
            </div>
            
            <div :class="{canvas : true, hasFoundation: foundation.enabled}" v-else>
                
                <div v-for="(obj, index) in calculated.list" :key="index" :class="'item ' + obj.class + (obj.id == selected ? ' selected' : ' ') ">
                    <div class="ct"  :style="obj.style">
                        <div class="name" @click.stop.prevent="" @mousedown.stop.prevent="select($event, obj.id)"><span :style="obj.nameStyle">{{ obj.name }}</span></div>
                        <div class="width" v-if="obj.width" ><span :style="'transform: rotate(' + (obj.deg * -1) +  'deg); '">{{ obj.width }}</span></div>
                    </div>
                    <div class="selection" :style="obj.selected">
                        <span class="tl"></span>
                        <span class="tr"></span>
                        <span class="bl"></span>
                        <span class="br"></span>
                        <template  v-if="!move.obj">
                            <div  @click.stop.prevent="">
                            <em v-if="obj.removable" @click="remove(obj)"></em>
                            
                            <div v-if="obj.type == 'column'" class="window" @click.prevent.stop>
                                <div class="row">
                                    <label>wysokość:</label>
                                    <span>{{ obj.item.data.height }} cm</span>
                                    <b @click.stop.prevent="obj.item.data.height -= obj.item.data.height - product.column.heightScale < 0 ? 0 : product.column.heightScale; calc()">-</b>
                                    <b @click.stop.prevent="obj.item.data.height  += product.column.heightScale; calc()">+</b>
                                </div>
                                <div class="row" v-if="product.column.multipleVariants">
                                    <a href="#" @click.stop.prevent="configureColumn.data = obj.item.data; configureColumn.visible = true; configureColumn.add = obj.item ">zmień</a>
                                </div>
                            </div>
                            <div v-if="obj.type == 'wall'" class="window" @click.prevent.stop>
                                <div class="row">
                                    <label>wysokość:</label>
                                    <span>{{ obj.item.data.height }} cm</span>
                                    <b @click.stop.prevent="obj.item.data.height -= obj.item.data.height - product.wall.heightScale < 0 ? 0 : product.wall.heightScale; calc()">-</b>
                                    <b @click.stop.prevent="obj.item.data.height  += product.wall.heightScale; calc()">+</b>
                                </div>
                                <div class="row" v-if="product.wall.type">
                                    <label>opcja:</label>
                                    <ul>
                                        <li :class="{active : obj.item.data.type == 1}" @click.stop.prevent="obj.item.data.type = 1; calc()">
                                            <span class="wall-1"></span>
                                        </li>
                                        <li :class="{active : obj.item.data.type == 2}" @click.stop.prevent="obj.item.data.type = 2; calc()">
                                            <span class="wall-2"></span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row" v-if="product.wall.multiply">
                                    <label @click.stop.prevent="obj.item.data.multiply = obj.item.data.multiply== 1 ? 2 : 1; calc()" style="cursor: pointer;"><input type="checkbox" :checked="obj.item.data.multiply == 2" disabled />ścianka podwójna</label>
                                </div>
                                <div class="row" v-if="product.wall.settings">
                                    <a href="#" @click.stop.prevent="configureWall.data = obj.item.data; configureWall.visible = true; configureWall.add = obj.item ">zmień</a>
                                </div>
                            </div>
                            <!--div v-else-if="false" class="window">
                                <div v-if="obj.type == 'wall'">
                                    
                                    <div class="row">
                                        <label>wysokość ścianki:</label>
                                        <span>{{ obj.column.wallHeight }} cm</span>
                                        <b @click.stop.prevent="obj.column.wallHeight -= obj.column.wallHeight - product.wall.scale < 0 ? 0 : product.wall.scale">-</b>
                                        <b @click.stop.prevent="obj.column.wallHeight += product.wall.scale">+</b>
                                    </div>
                                </div>
                                <div class="row"  v-if="['line', 'break'].includes(obj.type)">
                                    <label>podmurówka:</label>
                                    <span>{{ obj.column.foundation }} cm</span>
                                    <b @click.stop.prevent="obj.column.foundation -= obj.column.foundation - product.foundation.scale < 0 ? 0 : product.foundation.scale">-</b>
                                    <b @click.stop.prevent="obj.column.foundation += product.foundation.scale">+</b>
                                </div>
                                <div class="row" v-if="['line', 'break'].includes(obj.column.type) && product.foundation.peak">
                                    <label>daszek: </label>
                                    <select v-model="obj.foundationPeak"><option :value="null">brak</option><option v-for="p in product.foundation.peak" :key="p" :value="p">{{p}}</option></select>
                                </div>
                                <div class="row" v-if="obj.column.type == 'break'">
                                    <label>wysokość przęsła:</label>

                                    <span>{{ obj.column.columnSpan }} cm</span>
                                    <b @click.stop.prevent="obj.column.columnSpan -= obj.column.foundation - product.foundation.scale < 0 ? 0 : 1">-</b>
                                    <b @click.stop.prevent="obj.column.columnSpan += 1">+</b>
                                </div>
                            </div-->
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns top-buttons" @click.stop.prevent="" v-if="!gl">
            <div class="left">
                <button @click="add('column')" v-if="product.column">dodaj słupek</button>
                <button @click="add('wall')">dodaj ściankę</button>
                <button @click="add('gate')">dodaj bramę</button>
                <button @click="add('wicket')">dodaj furtkę</button>
                <template v-if="!product.foundation">
                    <button @click="add('line')">dodaj przerwę</button>
                </template>
                <template v-else>
                    <button @click="add('line')" class="two-line">dodaj przerwę <br />z możliwością podmurówki</button>
                    <div class="foundation-info" >
                        <template v-if="foundation.enabled">
                            <span class="num">
                                <span class="label">Podmurówka</span>
                                <span class="value">{{ foundation.height }}cm</span>
                                <b @click.stop.prevent="foundation.height -= foundation.height - product.foundation.heightScale < 0 ? 0 : product.foundation.heightScale;  calc();">-</b>
                                <b @click.stop.prevent="foundation.height += product.foundation.heightScale;  calc();">+</b>
                            </span>
                            <button class="gl" style="float: left" @click.prevent.stop="foundation.height = 0; foundation.variant = null; foundation.enabled = false; foundation.peak = null;  calc();">usuń</button>
                        </template>
                        <template v-else>
                            <button class="clear" style="float: left" @click.prevent.stop="checkFoundationSettings(true)">podmurówka</button>
                        </template>
                        
                        
                    </div>
                </template>
            </div>
            <div class="right">
                <button class="back" @click="back" v-if="history.length > 0">cofnij</button>
                <button class="clear" @click="clear()">wyczyść</button>
            </div>
        </div>
        <div class="btns bottom-buttons" @click.stop.prevent="">
            <div class="left"></div>
            <div class="right" v-if="calculated.list.length > 2">
                <button @click.stop.prevent="gl = true" class="gl" v-if="!gl">rzut izometryczny</button>
                <button @click.stop.prevent="gl = false" class="gl " v-if="gl">widok 2d</button>
                <button class="calc" @click.stop="contact.visible = true; sent = false" v-if="!gl">kalkulacja</button>
            </div>
        </div>
        <div class="invest-size">
            <span class="num">
                <span class="label">Długość inwestycji</span>
                <span class="value">{{ calculated.meters }}m</span>
                
            </span>
        </div>
        <div class="scale-btns">
            <b @click.stop.prevent="scale  -= scale - 0.1 > 0 ? 0.1 : 0 ">-</b>
            <b @click.stop.prevent="scale  += 0.1 ">+</b>
        </div>
        <div class="scale" :style="'width:' + (meter * scale) + 'px'" v-if="meter*scale > 35">
            1m
        </div>
        <div class="scale" :style="'width:' + (meter * scale * 10) + 'px'" v-else>
            10m
        </div>
        <modal container-class="contact configure-modal" v-if="foundation.visible" @mask-click="foundation.visible=false; foundation.add = null">
            <template v-slot:header>Podmurówka <span class="close" @click="foundation.visible=false; foundation.add = null">x</span></template>
            <template v-slot:body>
                <p>Wysokość</p>
                <div class="num">
                    <span class="value">{{ foundation.height }} cm</span>
                    <b @click.stop.prevent="foundation.height -= foundation.height - product.foundation.heightScale < 0 ? 0 : product.foundation.heightScale; checkFoundationSettings()">-</b>
                    <b @click.stop.prevent="foundation.height += product.foundation.heightScale; checkFoundationSettings()">+</b>
                </div>
                <template v-if="product.foundation.multipleVariants">
                <p>Wybierz wariant</p>

                <ul>
                    <li :class="{selected : id == foundation.variant}" v-for="(v , id) in product.foundation.variants" :key="id" @click="foundation.variant = id; foundation.peak = null; checkFoundationSettings()">
                        <span class="title">{{ v.products.join('+') }} - {{ v.width }}cm</span>
                        <img v-for="p in v.products" :src="'products/product/' + productId +'/'+ p + '.jpg'" :key="p" style="height: 170px" />
                    </li>
                </ul>
                </template>
                <div v-if="foundation.variant && product.foundation.variants[foundation.variant].peak && product.foundation.settings">
                    <p>Daszek</p>
                    <ul>
                        <li :class="{selected : id == foundation.peak}" v-for="(id) in product.foundation.variants[foundation.variant].peak" :key="id" @click="foundation.peak = id; checkFoundationSettings()">
                            <img  :src="'products/product/' + productId +'/'+ id + '.jpg'" :key="'p' + id" style="height: 170px" />
                        </li>
                    </ul>
                </div>
                <div class="row-btns">
                    <button type="submit" @click="foundation.enabled = true;foundation.visible = false; calc();"  v-if="foundation.correct">dodaj</button>
                </div>
            </template>
        </modal>
        <modal container-class="contact configure-modal" v-if="configureColumn.visible" @mask-click="configureColumn.visible=false; configureColumn.add = null">
            <template v-slot:header>Słupek <span class="close" @click="configureColumn.visible=false; configureColumn.add = null">x</span></template>
            <template v-slot:body>
                <p>Wysokość</p>
                <div class="num">
                    <span class="value">{{ configureColumn.data.height }} cm</span>
                    <b @click.stop.prevent="configureColumn.data.height -= configureColumn.data.height - product.column.heightScale < 0 ? 0 : product.column.heightScale; checkColumnSettings()">-</b>
                    <b @click.stop.prevent="configureColumn.data.height += product.column.heightScale; checkColumnSettings()">+</b>
                </div>
                <p>Wybierz wariant</p>

                <ul>
                    <li :class="{selected : id == configureColumn.data.variant}" v-for="(v , id) in product.column.variants" :key="id" @click="configureColumn.data.variant = id; configureColumn.data.peak = null; checkColumnSettings()">
                        <span class="title">{{ v.products.join('+') }} - {{ v.width }}cm</span>
                        <img v-for="p in v.products" :src="'products/product/' + productId +'/'+ p + '.jpg'" :key="p" style="height: 170px" />
                    </li>
                </ul>
                <div v-if="configureColumn.data.variant && product.column.variants[configureColumn.data.variant].peak">
                    <p>Daszek</p>
                    <ul>
                        <li :class="{selected : id == configureColumn.data.peak}" v-for="(id) in product.column.variants[configureColumn.data.variant].peak" :key="id" @click="configureColumn.data.peak = id; checkColumnSettings()">
                            <img  :src="'products/product/' + productId +'/'+ id + '.jpg'" :key="'p' + id" style="height: 170px" />
                        </li>
                    </ul>
                </div>
                <div class="row-btns">
                    <button type="submit" @click="saveColumnSettings()"  v-if="configureColumn.correct">{{ configureColumn.add == 'new' ? 'dodaj' : 'zapisz' }}</button>
                </div>
            </template>
        </modal>
        <modal container-class="contact configure-modal" v-if="configureWall.visible" @mask-click="configureWall.visible=false; configureWall.add = null">
            <template v-slot:header>Ścianka <span class="close" @click="configureWall.visible=false; configureWall.add = null">x</span></template>
            <template v-slot:body>
                <p>Wysokość</p>
                <div class="num">
                    
                    <span class="value">{{ configureWall.data.height }} cm</span>
                    <b @click.stop.prevent="configureWall.data.height -= configureWall.data.height - product.wall.heightScale < 0 ? 0 : product.wall.heightScale; checkWallSettings()">-</b>
                    <b @click.stop.prevent="configureWall.data.height += product.wall.heightScale; checkWallSettings()">+</b>
             
                </div>
                <div class="row">
                    <p>Opcja:</p>
                    <ul>
                        <li :class="{selected : configureWall.data.type == 1}" @click.stop.prevent="configureWall.data.type = 1">
                            <span class="wall-1"></span>
                        </li>
                        <li :class="{selected : configureWall.data.type == 2}" @click.stop.prevent="configureWall.data.type = 2">
                            <span class="wall-2"></span>
                        </li>
                    </ul>
                </div>
                <template v-if="product.wall.multipleVariants">
                    <p>Wybierz wariant</p>
                    
                    <ul>
                        <li :class="{selected : id == configureWall.data.variant}" v-for="(v , id) in product.wall.variants" :key="id" @click="configureWall.data.variant = id; configureWall.data.peak = null; checkWallSettings()">
                            <span class="title">{{ v.products.join('+') }} - {{ v.width }}cm</span>
                            <img v-for="p in v.products" :src="'products/product/' + productId +'/'+ p + '.jpg'" :key="p" style="height: 170px" />
                        </li>
                    </ul>
                </template>
                <div v-if="configureWall.data.variant && product.wall.variants[configureWall.data.variant].peak.length">
                    <p>Daszek</p>
                    <ul>
                        <li :class="{selected : id == configureWall.data.peak}" v-for="(id) in product.wall.variants[configureWall.data.variant].peak" :key="id" @click="configureWall.data.peak = id; checkWallSettings()">
                            <img  :src="'products/product/' + productId +'/'+ id + '.jpg'" :key="'p' + id" style="height: 170px" />
                        </li>
                    </ul>
                </div>
                <div class="row-btns">
                    <button type="submit" @click="saveWallSettings()"  v-if="configureWall.correct">{{ configureWall.add == 'new' ? 'dodaj' : 'zapisz' }}</button>
                </div>
            </template>
        </modal>
        <modal container-class="contact" v-if="contact.visible" @mask-click="contact.visible=false">
            <template v-slot:header>Kalkulacja <span class="close" @click="contact.visible=false">x</span></template>
            <template v-slot:body>
                
                <template v-if="sent">
                    <p>Kalkulacja została wysłana</p>
                </template>
                <template v-else>
                      <form @submit.stop.prevent="onSubmit(false)" ref="contact" @click.stop>
                        <p>Aby otrzymać kalkulację (dokument .pdf), <br />wypełnj ponizszy formularz.</p>
                        <div class="row">
                            <label>Miejsce inwestycji</label>
                            <input type="text" v-model="contact.city" required />
                        </div>
                        <div class="row">
                            <label>E-mail</label>
                            <input type="email"  v-model="contact.email" required />
                        </div>
                        <div class="row-btns">
                            <button type="submit">pobierz</button>
                            <!--button type="submit" @click.stop.prevent="onSubmit(true)">Pobierz</button-->
                        </div>
                      </form>
                </template>
            </template>
        </modal>
      
    </section>
</template>
<script>
import Modal from '@/widgets/Modal'
import axios from 'axios'
const typeface = require('three/examples/fonts/helvetiker_regular.typeface.json');

export default {
    components: {Modal},
    props: ['value'],
    mounted() {
        this.calc()
    },
    data() {
        let product = this.$db[this.value.product]
        return {
            history: [],
            objects: [],
            calculated: {
                list : [],
                meters: 0
            },
            meter : 68,
            font: typeface,
            id : 0,
            pi : Math.PI,
            move : {},
            gl: false,
            selected : null,
            contact: {firstName : null, lastName : null, city:null, email: null, visible: false},
            sent: false,
            scale: 1.0,
            foundation: {
                enabled: false,
                height: 0,
                peak: null,
                correct: false,
                visible: false,
            },
            configureColumn: {
                visible: false,
                correct: false,
                data: {
                    height: 90 - (90 % (product.column ? product.column.heightScale : 0)),
                    peak: null,

                    variant: null
                }
            },
            
            configureWall: {
                visible: false,
                correct: false,
                data: {
                    height: 0,
                    peak: null,
                    variant: null,
                    multiply: 1,
                    type: 2
                }
            }
        }
    },
    methods: {
        onWheel(e) {
            if (this.gl) {
                return
            }
            if (e.deltaY < 0) {
                this.scale += 0.01
                
            } else  if (e.deltaY > 0) {
                this.scale = Math.max(this.scale - 0.01, 0.01)
                
            }
            e.stopPropagation()
            e.preventDefault()
            this.calc()
        },
        depth(obj) {
            if (obj.type == 'column') {
                return obj.meters * 100
            }
            if (obj.info.type == 'wall') {
                return obj.meters * 0.9
            }
            
            return 0.05

        },
        calc() {
            let list = []
            let pos = 0
            let result = {
                meters : 0
            }
     
            Object.values(this.objects).forEach(item => {
           
                if (item.type == 'column' || item.type == 'point') {
                    let tmp = {
                        name : item.type == 'column' ? (pos++ + 1 + '').padStart(3, '0') : '',
                        type: item.type,
                        id : item.id,
                        item: item,
                        class: item.type,
                        meters: 0,
                        pos : item.pos,
                        removable: (item.end.length == 1 && item.begin.length == 0) || (item.end.length == 0 && item.begin.length == 1),
                        selected: 'left: ' + (item.pos.x * this.scale - 26) + 'px; top: ' + (item.pos.y * this.scale - 26) + 'px; width: ' + (52) + 'px; height: ' + (52) + 'px',
                        style: 'left: ' + (item.pos.x * this.scale) + 'px; top: ' + (item.pos.y* this.scale) + 'px'
                    }
                    if (item.type == 'column') {
                        tmp.meters = this.product.column.variants[item.data.variant].width/100
                    }
            
                    list.push(tmp)
                } else {
                    list.push(this.calcLine(this.objects[item.begin[0]].pos, this.objects[item.end[0]].pos, item))
                }
                
            })

            result.list = list

            this.detectProducts(result)
            
            result.lookAt = {x : this.$el.offsetWidth / this.meter / 2  * this.scale, y: 0, z : this.$el.offsetHeight / this.meter / 2  * this.scale}
            let x = [null, null]
            let y = [null, null]
            if (result.list.length) {
                result.list.forEach((item) => {
                    console.log(item)
                    let pos = item.point || item.pos
                    if (x[0] == null) {
                        x[0] = x[1] = pos.x
                        y[0] = y[1] = pos.y
                        return
                    }
                    x[0] = Math.min(x[0], pos.x)
                    x[1] = Math.max(x[1], pos.x)
                    
                    y[0] = Math.min(y[0], pos.y)
                    y[1] = Math.max(y[1], pos.y)
                })
                result.lookAt.x = (x[0] + (x[1] - x[0])/2) / this.meter * this.scale
                result.lookAt.z = (y[0] + (y[1] - y[0])/2) / this.meter * this.scale
            }
            
            
            
           

            this.calculated = result
        },
        wallElements(ids) {
            let res = {
                begin: [],
                internal: [],
                main: null
            }
            ids.forEach(id => {
                let product = {...this.product.products[id]}
                if (product.begin) {
                    res.begin.push(product)
                }
                if (product.main) {
                    res.main = product
                    res.internal.push(product)
                } else if (product.internal) {
                    res.internal.push(product)
                }

            })


            return res
        },
        detectProducts(data) {
            let products = {}
            Object.keys(this.product.products).forEach(productId => {
                products[productId] = {...this.product.products[productId], count: 0}
            })
            data.list.forEach(obj => {
                data.meters += obj.meters
                let item = obj.item
                obj.composition = {}
                Object.keys(this.product.products).forEach(productId => {
                    obj.composition[productId] = 0
                })
                if (item.type =='column') {
                    let rows = obj.rows = Math.ceil(item.data.height / this.product.column.heightScale)
                    let v = this.product.column.variants[item.data.variant]
                    obj.lines = v.products.length
                    for (let i =0; i< rows; i ++) {
                        v.products.forEach(p => {
                            products[p].count += v.multiply
                            obj.composition[p] += v.multiply
                        })
                    }
                    
                    if (item.data.peak) {
                        let peak = this.product.products[item.data.peak]
                        products[item.data.peak].count += Math.ceil(v.width / peak.width)
                        obj.composition[item.data.peak]++
                    }
                }
         
                if ((item.type =='line') && this.foundation.enabled) {
                    let rows = obj.rows = Math.ceil(this.foundation.height / this.product.foundation.heightScale)
                    let v = this.product.foundation.variants[this.foundation.variant]
                    let pr = this.product.products[v.products[0]]
                    obj.lines = 0
                    for (let i =0; i< rows; i ++) {
                        let cm = obj.meters * 100;
                        while (cm > 2) {
                            v.products.forEach(pid => {
                                let x = this.product.products[pid]
                                if (cm > 0) {
                                    cm -= x.width
                                    products[x.id].count++
                                    obj.composition[x.id]++
                                    i ==0 && obj.lines++
                                }
                            })
                        }
                    }
                 
                    if (this.foundation.peak) {
                        let peak = this.product.products[this.foundation.peak]
                        products[this.foundation.peak].count += Math.ceil(obj.meters * 100 / peak.width)
                        obj.composition[this.foundation.peak] += Math.ceil(obj.meters * 100 / peak.width)
                    }
                    
                    
                }
                if (item.type =='wall') {
                    let rows = obj.rows = Math.ceil(item.data.height / this.product.wall.heightScale)
                    let v = this.product.wall.variants[item.data.variant]
                    let elements = this.wallElements(v.products)
                    let hasBegin = this.objects[item.begin[0]].type == 'point'
                    let hasEnd = this.objects[item.end[0]].type == 'point'
                    let hasBeginCorner = false
                    let hasEndCorner = false
                    obj.lines = 0
                    if (hasBegin) {
                        this.objects[item.begin[0]].end.forEach(pid => {
                            if (this.objects[pid].type == 'wall') {
                                hasBeginCorner = true
                            }
                        })
                    }
                    if (hasEnd) {
                        this.objects[item.end[0]].begin.forEach(pid => {
                            if (this.objects[pid].type == 'wall') {
                                hasEndCorner = true
                            }
                        })
                    }
                    

                    let prevStart = null
                    let anyBegin = false
                    
                    for (let i =0; i< rows; i ++) {
                        let cm = obj.meters * 100 * item.data.multiply
                        let begin = null
                        let end = null
                        
                        if (hasBeginCorner && v.corner) {
                            if (i%2 == 0) {
                                begin = this.product.products[v.corner]
                            } else {
                                cm -= this.product.products[v.corner].width/2
                                products[this.product.products[v.corner].id].count++
                                obj.composition[this.product.products[v.corner].id]++
                            }
                            anyBegin = true
                        } else if (hasBegin && elements.begin.length) {
                            begin = elements.begin[item.data.type == 2 ? i%2 : 1]
                            anyBegin = true
                        }

                        if (hasEndCorner && v.corner) {
                            if (i%2 == 1) {
                                end = this.product.products[v.corner]
                            } else {
                                cm -= this.product.products[v.corner].width/2
                                products[this.product.products[v.corner].id].count++
                                obj.composition[this.product.products[v.corner].id]++
                            }
                        } else if (hasEnd && elements.begin.length) {
                            end = elements.begin[item.data.type == 2  ? (i + 1)%2 : 1]
                        }

                        let other = []
                        
                        if (begin) {
                            products[begin.id].count++
                            obj.composition[begin.id]++
                            cm -= begin.width
                            i ==0 && obj.lines++
                        }
                        if (end) {
                            products[end.id].count++
                            obj.composition[end.id]++
                            cm -= end.width
                            i ==0 && obj.lines++
                        }
                        if (elements.main) {
                            
                            while (cm >= elements.main.width) {
                                products[elements.main.id].count++
                                obj.composition[elements.main.id]++
                                cm -= elements.main.width
                                i ==0 && obj.lines++
                            }
                        }
                        
                        let sumOther = 0
                        elements.internal.forEach(m => sumOther += m.width)
                        if (!anyBegin && i % 2 == 0 && item.data.type == 2 && cm > 2 && item.data.multiply == 1) {
                            
                            cm -= elements.internal[0].width/2
                            products[elements.internal[0].id].count++
                            obj.composition[elements.internal[0].id]++
                            i ==0 && obj.lines++

                        }
                    
                        while (cm > sumOther) {
                            elements.internal.forEach(x => {
                                if (cm > 2) {
                                    cm -= x.width
                                    products[x.id].count++
                                    obj.composition[x.id]++
                                    i ==0 && obj.lines++
                                }
                            })
                        }
                        
                        if (cm > 0) {
                            elements.internal.forEach(x => {
                                if (cm > 2 && x.width >= cm) {
                                    cm -= x.width
                                    products[x.id].count++
                                    obj.composition[x.id]++
                                    i ==0 && obj.lines++
                                }
                            })
                            let c = elements.internal
                            c.sort((a, b) => {
                                return a.width - b.width
                            })
                          
                            if (cm > 2) {
                                cm -= c[0].width
                                products[c[0].id].count++
                                obj.composition[c[0].id]++
                                i ==0 && obj.lines++
                            }
                            
                        }
                  
                        prevStart = begin
                    }
                    

                    if (item.data.peak) {
                        let peak = this.product.products[item.data.peak]
                        products[item.data.peak].count += Math.ceil(obj.meters * 100 / peak.width)

                        obj.composition[item.data.peak] += Math.ceil(obj.meters * 100 / peak.width)
                    }
                }
            })
            data.products = products
            
            data.meters = Math.ceil(data.meters * 100) / 100
        },
        clear() {
            this.history = []
            this.objects = {}
            this.selected = null
            this.foundation.enabled = false
            this.calc()
        },
        remove(column) {
       
            if (column.item.end.length) {
                let prev = this.objects[column.item.end[0]]
                let prevCol = this.objects[prev.begin[0]]
                
                delete this.objects[column.id]
                delete this.objects[prev.id]
        
                prevCol.begin = prevCol.begin.filter(x => x != prev.id)
                this.selected = prevCol.id
          
             
            } else {
                let prev = this.objects[column.item.begin[0]]
                let prevCol = this.objects[prev.end[0]]
                
                delete this.objects[column.id]
                delete this.objects[prev.id]
                prevCol.end = prevCol.end.filter(x => x != prev.id)
                this.selected = prevCol.id
            }
            

            this.pushHistory()
        },
        back() {
            this.selected = null
            if (this.history.length > 1) {
                this.history.pop()
                let tmp = this.history[this.history.length - 1]
                if (Array.isArray(tmp)) {
                    this.objects = {}
                    
                    tmp.forEach(x => {
                        if (x == null) {
                            return
                        }
                        this.objects[x.id] = x
                    })
                } else {
                    this.objects = tmp
                }
                let l = Object.keys(this.objects)
          
                this.selected = l[l.length-1]
                this.calc()
            } else {
                this.clear()
            }
            
        },
        defaults(type) {
            if (type == 'column' || type == 'point') {
                return {...this.configureColumn.data}
            }
            if (type == 'wall') {
                return {...this.configureWall.data}
            }
            return {}
        },
        saveColumnSettings() {
            if (this.configureColumn.add == 'new') {
                this.add('column')
                this.configureColumn.add = null
            } else {
                this.objects[this.selected].data = {...this.configureColumn.data}
                this.configureColumn.add = null
            }
            this.configureColumn.visible = false
            this.calc()
        },
        checkColumnSettings() {
            if (Object.keys(this.product.column.variants).length == 1) {
                this.configureColumn.data.variant = Object.keys(this.product.column.variants)[0]
            } else if (!this.configureColumn.data.variant) {
                this.configureColumn.correct = false
                return
            }

            let v = this.product.column.variants[this.configureColumn.data.variant]
            
            if (v.peak && v.peak.length) {
                if (v.peak.length == 1) {
                    this.configureColumn.data.peak = v.peak[0]
                }
                if (!this.configureColumn.data.peak) {
                    this.configureColumn.correct = false
                    return
                }
            }
            
            this.configureColumn.correct = this.configureColumn.data.height > 0
        },
        saveWallSettings() {
            if (this.configureWall.add == 'new') {
                this.add('wall')
                this.configureWall.add = null
            } else {
                this.objects[this.selected].data = {...this.configureWall.data}
                this.configureWall.add = null
            }
            this.configureWall.visible = false
            this.calc()
        },
        
        checkWallSettings() {
            if (Object.keys(this.product.wall.variants).length == 1) {
                this.configureWall.data.variant = Object.keys(this.product.wall.variants)[0]
            } else if (!this.configureWall.data.variant) {
                this.configureWall.correct = false
                return
            }


            let v = this.product.wall.variants[this.configureWall.data.variant]
            if (v.peak && v.peak.length) {
                if (v.peak.length == 1) {
                    this.configureWall.data.peak = v.peak[0]
                }
                if (!this.configureWall.data.peak) {
                    this.configureWall.correct = false
                    return
                }
            }
            
            this.configureWall.correct = this.configureWall.data.height > 0
        },
        checkFoundationSettings(open) {
            if (Object.keys(this.product.foundation.variants).length == 1) {
                this.foundation.variant = Object.keys(this.product.foundation.variants)[0]
            } else if (!this.foundation.variant) {
                this.foundation.correct = false
                if (open) {
                        this.foundation.visible = true
                    }
                return
            }
      


            let v = this.product.foundation.variants[this.foundation.variant]
            if (v.peak && v.peak.length) {
                if (v.peak.length == 1) {
                    this.foundation.peak = v.peak[0]
                }
                if (!this.foundation.peak) {
                    this.foundation.correct = false
                    if (open) {
                        this.foundation.visible = true
                    }
                    return
                }
            }
            
            this.foundation.correct = this.foundation.height > 0
            if (open && !this.foundation.correct) {
                this.foundation.visible = true
            }
        },

        add(type) {
            if (type == 'column' && this.configureColumn.correct == false) {
                this.checkColumnSettings()
                if (!this.configureColumn.correct) {
                    this.configureColumn.visible = true
                    this.configureColumn.add = 'new'
                    return
                }
            }
            if (type == 'wall' && this.configureWall.correct == false) {
                this.checkWallSettings()
                if (!this.configureWall.correct) {
                    this.configureWall.visible = true
                    this.configureWall.add = 'new'
                    return
                }
            }
            if (Object.keys(this.objects).length == 0) {
                let l = Math.ceil(this.$el.offsetWidth / this.meter / this.scale / 2) 
                let t = Math.ceil(this.$el.offsetHeight / this.meter / this.scale / 2)
                

                this.objects[++this.id] = {
                    type : type == 'column' ? 'column' : 'point',
                    pos : {x: l * this.meter, y : t * this.meter },
                    data: this.defaults(type == 'column' ? 'column' : 'point'),
                    id: this.id,
                    begin: [],
                    end: []
                }
                this.selected = this.id;
           
                if (type == 'column') {
                    this.pushHistory()
                    return
                }
            }
       
            
            let originId =  Object.keys(this.objects)[Object.keys(this.objects).length-1]
            if (this.selectedObject && this.selectedObject.type == 'point' && type == 'column') {
                this.selectedObject.type = 'column'
                this.selectedObject.data = this.defaults('column')
                this.pushHistory()
                return
            }
            if (this.selectedObject) {
                if (this.selectedObject.type == 'column' || this.selectedObject.type == 'point') {
                    originId = this.selectedObject.id
                } else {
                    originId = this.selectedObject.end[0]
                }
            }
            
            let origin = this.objects[originId].pos
            let newPos = { ...origin }

            

            if (Object.keys(this.objects).length > 1) {
                let a = this.objects[originId]
                let b = null
                let plus = a.begin.length
                if (a.end.length) {
                    let tmp = this.objects[this.objects[a.end[a.end.length-1]].begin[0]]
                    b = a
                    a = tmp
                } else {
                    b = this.objects[this.objects[a.begin[a.begin.length-1]].begin[0]]
                }
           


                var rad =  Math.atan2(b.pos.y-a.pos.y, b.pos.x-a.pos.x) + 1/8 * plus
                newPos.x += (this.meter *2) * Math.cos(rad) - (0) * Math.sin(rad)
                newPos.y += (this.meter *2) * Math.sin(rad) + (0) * Math.cos(rad)
            } else {
                newPos.x += this.meter *2
            }
            let tmp = {
                type : type,
                pos : origin,
                id: ++this.id,

                begin: [],
                end: []
            }
            this.objects[tmp.id] = tmp
            if(type == 'column') {
                tmp.type = 'line'
            }
            tmp.data = this.defaults(tmp.type)

            let end = {
                type : type == 'column' ? 'column' : 'point',
                pos : newPos,
                id: ++this.id,
                begin: [],
                end: []
            }
            end.data = this.defaults(end.type)
            tmp.begin.push(originId)

            tmp.end.push(end.id)

            this.objects[originId].begin.push(tmp.id)
            end.end.push(tmp.id)

            this.objects[end.id] = end
            
            this.selected = this.id
            
            this.pushHistory()
        },
        pushHistory() {
            this.history.push(JSON.parse(JSON.stringify(this.objects)))
            this.calc()
        },
        select(event, id) {
            let obj = this.objects[id]
            this.selected = id

            if (obj.type == 'column' || obj.type == 'point') {
                this.move = {
                    item : obj,
                    clientX : event.clientX,
                    clientY : event.clientY,
                    
                }
                document.onmousemove = this.columnDrag
                document.onmouseup = this.stopDrag
            }
            this.calc()
            
        },
        unselect() {
            this.selected = null
            this.calc()
        },
        columnDrag(event) {
            event.preventDefault()
            if (!this.move || !this.move.item)
                return
            this.move.any = true
            this.move.movementX = this.move.clientX - event.clientX
            this.move.movementY = this.move.clientY - event.clientY
            this.move.clientX = event.clientX
            this.move.clientY = event.clientY
            this.move.item.pos.x -= this.move.movementX / this.scale
            this.move.item.pos.y -= this.move.movementY / this.scale

             this.calc()
        },
        stopDrag() {
            if (this.move.any) {
                this.pushHistory()
            }
            document.onmouseup = null
            document.onmousemove = null
            this.move = {}
            this.calc()
        },
        onSubmit(download) {
            download = download||false
            if (!this.$refs.contact.checkValidity()) {
                this.$refs.contact.reportValidity()
                return
            }
       
            axios.post('api/public/', {...this.contact, download : download ? 1 : 0, product : this.product, color: this.$parent.colorLabel(this.value.color), objects: this.objects, calculated: this.calculated},
                {responseType:  'blob'}
            ).then(resp => {
                let URL = window.URL || window.webkitURL
                
                let downloadUrl = URL.createObjectURL(resp.data)
               
                let a = document.createElement("a");
                a.target = '_blank'
                // safari doesn't support this yet
                if (typeof a.download === 'undefined') {
                    window.location.href = downloadUrl;
                } else {
                    a.href = downloadUrl;
                    a.download = 'kalkulacja.pdf';
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function(){
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(downloadUrl);
                      }, 100);
                }
            })
        },
        calcLine (a, b, column) {
            let min = [Math.min(a.x, b.x) - 20, Math.min(a.y, b.y) -25]
            let max = [Math.max(a.x, b.x) + 20, Math.max(a.y, b.y) + 25]

            var length = Math.hypot(b.y-a.y, b.x-a.x),
            rad =  Math.atan2(b.y-a.y, b.x-a.x),
            deg    = rad * 180 / Math.PI
            
            let name = {wall : 'ŚCIANKA', 'gate' : 'BRAMA', 'wicket' : 'FURTKA'}[column.type]||''
            let nameStyle = ''
            if (deg < -90 || deg > 90) {
                nameStyle = 'transform: rotate(180deg)'
            }
            
            return {
                type: column.type,
                class: 'b ' + column.type, 
                id : column.id,
                name: name,
                item: column,
                removable: false,
                point: {x : (a.x + b.x) / 2, y:  (a.y + b.y) / 2},
                nameStyle: nameStyle,
                width : Math.ceil(length * 20 / this.meter) / 20 + 'm',
                meters : Math.ceil(length * 20 / this.meter) / 20,
                style: 'left: ' + (a.x * this.scale) + 'px; top: ' + ((a.y* this.scale) - (column.type == 'line' ? 3 : 8)) + 'px; width: ' + (length * this.scale) + 'px; transform: rotate(' + deg + 'deg)',
                deg : deg,
                rad: rad,
                pos: column,
                selected: 'left: ' + (min[0]* this.scale) + 'px; top: ' + (min[1]* this.scale) + 'px; width: ' + (max[0]* this.scale - min[0]* this.scale) + 'px; height: ' + (max[1]* this.scale - min[1]* this.scale) + 'px'
            }
        }
    },

    computed: {
        product() {
            return this.$db[this.value.product];
        },
        productId() {
            return this.value.product
        },
        selectedObject() {
            if (this.selected == null) {
                return null
            }
            return this.objects[this.selected]
        }
    }
}
</script>
<style lang="scss">
#canvas {
    width: 100vw;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}
.configure {
    position: relative;
    overflow: hidden;
    .gl-canvas {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    button {
        font-size: 16px;
        background: #878e00;
        padding: 0px 17px;
        color: white;
        line-height: 33px;
        border: 0px solid;
        border-radius: 5px;
        cursor:pointer;
        transition: all .4s;
        text-transform: uppercase;
        &:hover {
            background-color: #acb500;
        }
        &.two-line {
            font-size: 12px;
            line-height: 17px;
        }
    }
    .foundation-info {
        display: inline-block;
        padding-left: 20px;
        border-left: 2px solid silver;
        vertical-align: middle;
    }
    .btns {
        display: flex;
        position: absolute;
        left: 0px;
        padding: 0px 30px;
        width: 100vw;
        z-index: 0;
        button {
            position: relative;
            z-index:30;
            &.clear {
                background: #7f7f7f;
            }
            &.gl {
                background: #ff5500;
            }
            &.back {
                background: #3e3e3e;
            }
            &.calc {
                background: #1e4d2c;
            }
            &:hover {
                opacity: .8;
            }

            
        }

        .left {
            flex: 1;
            button {
                margin-right: 27px;
                float: left;
            }
        }
        .right {
            button {
                margin-left: 27px;
                
                
            }
        }
    }
    .invest-size {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    .scale-btns {
        position: absolute;
        bottom: 18px;
        left: 280px;
         b {position: relative; top: 0px;cursor: pointer; color: white; font-size: 18px; background: #878e00; border-radius: 2px; width: 28px; height: 28px; text-align: center; line-height: 26px; font-weight: normal; font-family: Ubuntu; display: inline-block; margin-left: 5px; vertical-align: middle;} 
    
    }
    .scale {
        position: absolute;
        bottom: 30px;
        left: 360px;
        border-bottom: 1px solid black;
        text-align: center;
        &::before, &::after {
            content: '';
            height: 20px;
            width: 1px;
            background: black;
            top: 13px;
            position: absolute;
        }
        &::before {
            left: 0px;
        }
        &::after {
            right: 0px;
        }
    }
    .top-buttons {
        top: 30px;
    }
    .num {
        display: flex;
        float: left;
        margin-right: 10px;
        user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
        padding-top: 3px;
        padding-right: 20px;
        .value {
            border: 2px solid #878e00;
            background: white;
            color: #848484;
            line-height: 24px;
            display: inline-block;
            border-radius: 3px;
            margin-right: 5px;
            padding: 0px 3px;
            min-width: 80px;
            text-align: center;
        }
        .label {
            font-size: 16px;
            line-height: 26px;
            padding-right: 10px;
        }
        b {position: relative; top: 0px;cursor: pointer; color: white; font-size: 18px; background: #878e00; border-radius: 2px; width: 28px; height: 28px; text-align: center; line-height: 26px; font-weight: normal; font-family: Ubuntu; display: inline-block; margin-left: 5px; vertical-align: middle;} 
    }
    .bottom-buttons {
        bottom: 30px;
        .right {
            
            button {
                
                line-height: 43px;
                &:hover {
                    opacity: .8;
                }
            }
        }
    }
    .canvas-wrap {
        flex: 1;
        position: relative;
        width: 100vw;
        height: 100%;
        overflow: auto;
        background: url('../assets/images/conf.png');
        background-position: -1px -1px;
        &.has-gl {
            background: white;
        }
    }
    .canvas {
        position: absolute;
        top: 0px;
        left: 0px;
        &.hasFoundation {
            .line .ct::after {
                content: '';
                display: block;
                border-bottom: 2px solid red;
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
            }
        }
        .item {
            .ct {
                position: absolute;
            }
            &.column, &.point {
                .ct {
                    z-index: 5;
                }
            }
            &.column .name, &.point .name {
                display: block;
                background: #acb500;
                width: 32px;
                height: 32px;
                z-index: 3;
                line-height: 33px;
                text-align: center;
                color: white;
                font-size: 12px;
                border-radius: 3px;
                position: absolute;
                top: -16px;
                left: -16px;
                cursor: pointer;
            }
            &.point .name {
                border-radius: 100px;
            }
            &.b {
                z-index: 2;
                .ct {
                    transform-origin: left 50%;
                    z-index: 2;
                }
                .name {
                    width: 100%;
                    position: relative;
                    border: 3px solid #878e00;
                    
                    cursor: pointer;
                    overflow: hidden;
                    span {
                        display: block;
                        text-align: center;
                    }
                }
                .width {
                    position: absolute;
                    font-size: 18px;
                    font-weight: bold;
                    left: 0px;
                    top: -25px;
                    text-align: center;
                    width: 100%;
                    color: #878e00;
                    span {
                        position: relative;
                        display: inline-block;
                        bottom: 10px;
                    }
                }
            }
            
            &.gate, &.wicket, &.wall {
                .name {
                    text-align: center;
                    height: auto;
                    background: white;
                    font-size: 12px;
                    color: #878e00;
                    line-height: 13px;
                    font-weight: bold;
                    border: 3px solid #878e00;
                }
            }
            .selection {display: none}
            &.selected {
                .selection {
                     display: block;
                     position: absolute;
                     > span {
                         position: absolute;
                         display: block;
                         background: black;
                         z-index: 30;
                         &::after {
                             content: '';
                             background: black;
                             display: block;
                         }
                     }
                     .tl {
                         height: 5px;
                         width: 20px;
                         top: 0px;
                         left: 0px;

                         &::after {
                             height: 20px;
                             width: 5px;
                         }
                     
                     }
                     .tr {
                         height: 5px;
                         width: 20px;
                         top: 0px;
                         right: 0px;

                         &::after {
                             height: 20px;
                             width: 5px;
                             float: right;
                         }
                     }
                     .br {
                         height: 5px;
                         width: 20px;
                         bottom: 0px;
                         right: 0px;

                         &::after {
                             height: 20px;
                             width: 5px;
                             float: right;
                             position: absolute;
                             bottom: 0px;
                             right: 0px;
                         }
                     }
                      .bl {
                         height: 5px;
                         width: 20px;
                         bottom: 0px;
                         left: 0px;

                         &::after {
                             height: 20px;
                             width: 5px;
                             float: left;
                             position: absolute;
                             bottom: 0px;
                             left: 0px;
                         }
                     }
                }
                
                z-index: 1;
                &.b .name {
                    border-color: black;
                    color: black;
                }
                &.column .name, &.point .name {
                    background: black;
                }
                .width {
                    color: black;
                }

                em {
                    background: white url(../assets/images/remove.png) no-repeat center center;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
                    width: 25px;
                    height: 25px;
                    display: block;
                    position: absolute;
                    top: 0px;
                    right: -30px;
                    border-radius: 5px;
                    cursor: pointer;
                    z-index: 55;
                }
                .window {
                    background: white;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
                    display: block;
                    position: absolute;
                    padding: 18px;
                    top: 0px;
                    width: 275px;
                    right: -280px;
                    border-radius: 5px;
                    z-index: 50;
                    span {padding: 0px 5px}
                    b {position: relative; top: 3px;cursor: pointer; color: white; font-size: 18px; background: #878e00; border-radius: 2px; width: 18px; height: 18px; text-align: center; line-height: 16px; font-weight: normal; font-family: Ubuntu; display: inline-block; margin-left: 5px; vertical-align: middle;} 
                    i { font-style: normal;}
                    a {
                        color: black;
                    }
                    .row {
                        display: flex;
                        padding: 5px 0px;
                        align-items:center;
                        input[type=number] {
                            width: 50px;
                            border: 1px solid #000000;
                            border-radius: 3px;
                            padding: 0px 5px;
                            margin: 0px 5px;
                        }
                        ul {
                            list-style: none;
                            padding: 0px;
                            margin: 0px;
                            white-space: nowrap;
                            padding-left: 10px;
                            padding-right: 10px;
                            li {
                                display: inline-block;
                                span {
                                    display: inline-block;
                                    width: 100px;
                                    height: 45px;
                                    border-radius: 3px;
                                    cursor:pointer;
                                    padding: 0px;
                                    &.wall-1 {
                                        background: url(../assets/images/wall1.png) no-repeat center center;
                                    }
                                    &.wall-2 {
                                        background: url(../assets/images/wall2.png) no-repeat center center;
                                    }
                                }
                                &.active span {
                                    background-color: #acb500;
                                }
                            }
                        }
                    }
                }
                

            }
        }
    }
    .contact {
         background-color: rgba(0, 0, 0, .6);
         .modal-container {
             width: 450px;
             .modal-header {
                 color: #878e00;
                 text-transform: uppercase;
                 letter-spacing: 1px;
                 font-size: 30px;
                 text-align: center;
                 font-weight: 300;
                 position: relative;
                 .close {
                     font-size: 17px;
                     color: #000000;
                     position: absolute;
                     top: -10px;
                     cursor: pointer;
                     right: -15px;
                     padding: 5px;
                     display: block;
                 }
             }
             p {
                 padding-top: 10px;
                 padding-bottom: 30px;
                 text-align: center;
                 color: #535353;
             }
             label {
                 display: block;
             }
             input {
                 width: 100%;
                 margin-bottom: 20px;
                 background: #ececec;
                 border: 0px solid;
                 padding: 8px 5px;
                 border-radius: 5px;
             }
             button {
                 width: 100%;
                 text-align: center;
                 line-height: 40px;
                 margin-top: 10px;
             }
             .row-btns {
                 display: flex;
                 height: 50px;
                 button:last-child {
                     margin-left: 20px;
                 }
                 button:first-child {
                     margin-left: 0px !important;
                 }
             }
         }
    }
    .configure-modal {
        .modal-container {
            width: 1120px;
            text-align: center;
            .modal-body {
                margin: 0px;
            }
            .num {
                display: inline-block;
                float: none;
            }
            p {
           
                padding: 0px;
                padding-top: 10px;
            }
            span.wall-1, span.wall-2 {
                display: inline-block;
                width: 100px;
                height: 45px;
                border-radius: 3px;
                cursor:pointer;
                padding: 0px;
                
            }
            span.wall-1 {
                background: url(../assets/images/wall1.png) no-repeat center center;
            }
            span.wall-2 {
                background: url(../assets/images/wall2.png) no-repeat center center;
            }
           
            ul {
                list-style: none;
                display: flex;
                margin: 0px;
                padding-left: 0px;
                justify-content: center;
                li {
                    display: inline-block;
                    cursor: pointer;
                    border: 1px solid silver;
                    padding: 10px;
                    margin: 10px;
                    border-radius: 4px;
                    padding: 4px;
                    &.selected {
                        border: 1px solid black;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                    .title {
                        display: block;
                        text-align: center;
                    }
                    img {
                        display: inline;
                    }
                }
            }
        }
    }
}

</style>
