<template>
  <div id="app">
    <header>
      <a href="#" @click.stop.prevent="onReset" class="left logo"></a>
      <h1>Konfigurator ogrodzeń</h1>
      <div class="right">
        <span v-if="model.product && step > 0" @click="step = 0, model.product = null">
          wybrany produkt: <strong>{{ $db[model.product].name }}</strong>
          <br />
          <a href="#" click.stop="step = 0, model.product = null" v-if="step >1">zmień produkt</a>
        </span>
      </div>
    </header>
    <main>
      <page-main @product="onProduct" v-if="step == 0" />
      <page-color @color="onColor" v-model="model" @prev="step = 0" @next="step = 2" v-else-if="step == 1" />
      <page-conf v-model="model" @prev="step = 1" v-else-if="step == 2" />
    </main>
    <footer>
      <p>Copyright &copy; 2021 libet.pl</p>
      <small>Developed by Output42</small>
    </footer>
  </div>
</template>

<script>
import PageMain from './components/Main.vue'
import PageColor from './components/Color.vue'
import PageConf from './components/Configure.vue'

export default {
  name: 'App',
  components: {
    PageMain, PageColor,
    PageConf
  },
  data() {
    return {
      model : {
        product: null
      },
      step : 0,
      labels: {
          'torfowy-braz' : 'torfowy brąz',
          'bianco' : 'bianco carrara',
          'bialy' : 'biały',
          'piaskowo-bezowy' : 'piaskowo-beżowy',
          'pergaminowa-biel' : 'pergaminowa biel'
      }
    }
  },
  methods: {
    onProduct(id) {
      this.model.product = id
      this.step = 1
    },
    colorLabel(color) {
        return this.labels[color] || color
    },
    onReset() {
      this.model = {
        product: null
      }
      this.step = 0
    },
    onColor() {

    }
  }
  
}
</script>

<style lang="scss">

</style>
